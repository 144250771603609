import "@popperjs/core/dist/umd/popper.min.js";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
import "./src/styles/custom.scss";
import "./src/styles/hero.scss";
import "./src/styles/layout.scss";
// Import slick css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const shouldUpdateScroll = () => {
  return false;
};
